import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PaymentsIcon from "@mui/icons-material/Payments";
import PlaceIcon from "@mui/icons-material/Place";
import PublicIcon from "@mui/icons-material/Public";
import SmsFailedOutlinedIcon from "@mui/icons-material/SmsFailedOutlined";
import { Box, Chip, Link, Paper, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { DateTime } from "luxon";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Utils from "../../utils/Utils";
import PostDirectory from "../PostDirectory";
import User from "../User";
import UserAvatar from "../UserAvatar";
import LinkPreview from "../post/LinkPreview";
import Commitment from "./Commitment";
import CommentSection from "./EventCommentSection";
import ReactionAndComments from "./ReactionAndComments";
import ReportEvent from "./ReportEvent";

const { postBorderColors, currencies, splitText, urlRegex, formatNumber } =
  Utils;

export default function Event({
  event,
  profile,
  deleteEvent,
  setNewEventModalOpened,
  setEditingEvent,
  interactionDisabled,
  pageType,
}) {
  const { t, i18n } = useTranslation(["posts-events"]);
  const { language } = i18n;
  let links = event.description.match(urlRegex);
  links = links?.map((link) => {
    if (!link.startsWith("http")) {
      return `https://${link}`;
    }
    return link;
  });
  const segments = splitText(event.description);
  const [isCommentSectionOpened, setIsCommentSectionOpened] = useState(false);
  const [isReportEventModal, setIsReportEventModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const editCurrentPost = () => {
    setEditingEvent(event);
    setNewEventModalOpened(true);
  };
  return (
    <Paper
      key={event._id}
      elevation={2}
      sx={{
        padding: "1rem",
        my: 2.5,
        borderRadius: 3,
        boxShadow: `3px 3px 10px ${
          postBorderColors[event.isCommercial ? "commercial" : event.type]
        },-3px -3px 10px ${
          postBorderColors[event.isCommercial ? "commercial" : event.type]
        }`,
      }}
    >
      <Box sx={{ display: "flex" }}>
        <UserAvatar
          userId={event.authorID}
          user={event.author}
          username={event.author.username}
        />
        <Box sx={{ display: "flex", flexFlow: "column" }}>
          <Box sx={{ display: "flex" }}>
            {!event.author.username || event.author.deletedAccount ? (
              <Typography>{t("deletedUser")}</Typography>
            ) : (
              <User
                username={event.author.username}
                pageType={pageType}
                posts={event}
                showMessage={true}
              />
            )}
            {event.createdAt !== event.updatedAt && (
              <Chip size="small" label={t("edited")} />
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ ml: 1, mr: 0.5 }} variant="p" fontSize="small">
              {DateTime.fromISO(event.createdAt)
                .setLocale(language)
                .toRelative()}
            </Typography>
            <Box
              sx={{ display: "flex", alignItems: "center" }}
              fontSize="small"
            >
              &#183;
            </Box>
            <Box
              sx={{ display: "flex", alignItems: "center", ml: 0.5 }}
              fontSize="small"
            >
              {event.isPrivate ? (
                <LockIcon fontSize="0.5rem" />
              ) : (
                <PublicIcon fontSize="0.5rem" />
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={{ ml: "auto" }}>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {event.authorID === profile?._id && (
              <MenuItem
                onClick={() => {
                  editCurrentPost();
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">{t("editEvent")}</Typography>
              </MenuItem>
            )}
            {event.authorID === profile?._id && (
              <MenuItem
                onClick={() => {
                  deleteEvent(event._id);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">{t("deleteEvent")}</Typography>
              </MenuItem>
            )}
            {profile?._id && (
              <MenuItem
                onClick={() => {
                  setIsReportEventModal((prev) => !prev);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <SmsFailedOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">{t("reportEvent")}</Typography>
              </MenuItem>
            )}
          </Menu>
        </Box>
      </Box>
      <PostDirectory post={event} />
      <Stack direction="row" sx={{ flexWrap: "wrap" }}>
        <Chip
          label={`${
            currencies.find((c) => c.name === event.costs.currency)?.sign
          }${formatNumber(event.costs.amount)}`}
          icon={<PaymentsIcon />}
          color="info"
          variant="outlined"
          sx={{ fontWeight: "bold", m: 0.5 }}
        />
        <Chip
          label={DateTime.fromISO(event.dateTime)
            .setLocale(language)
            .toLocaleString(DateTime.DATE_HUGE)}
          icon={<CalendarMonthIcon />}
          color="success"
          sx={{ fontWeight: "bold", m: 0.5 }}
          variant="outlined"
        />
        <Chip
          label={DateTime.fromISO(event.dateTime)
            .setLocale(language)
            .toLocaleString(DateTime.TIME_SIMPLE)}
          icon={<AccessTimeIcon />}
          color="warning"
          variant="outlined"
          sx={{ fontWeight: "bold", m: 0.5 }}
        />
        <Chip
          label={event.address}
          icon={<PlaceIcon />}
          color="secondary"
          variant="outlined"
          sx={{
            fontWeight: "bold",
            m: 0.5,
            height: "unset",
            "& .MuiChip-label": {
              textOverflow: "unset",
              whiteSpace: "normal",
            },
          }}
        />
      </Stack>
      <Typography
        variant="h5"
        fontWeight="bold"
        sx={{ mb: 1, whiteSpace: "pre-wrap" }}
      >
        {event.name}
      </Typography>
      <Typography sx={{ mb: 1, whiteSpace: "pre-wrap" }}>
        {segments.map((segment) =>
          segment.isLink ? (
            <Link
              key={segment.text}
              href={segment.url}
              target="_blank"
              sx={{ wordBreak: "break-all" }}
            >
              {segment.text}
            </Link>
          ) : (
            <Typography key={segment.text} component="span">
              {segment.text}
            </Typography>
          )
        )}
      </Typography>
      {links?.map((link) => (
        <LinkPreview key={link} url={link} />
      ))}
      <ImageList variant="masonry" cols={2} gap={4}>
        {event.images.map((img) => (
          <ImageListItem key={img.key}>
            <img
              className="rounded-md"
              srcSet={img.url}
              src={img.url}
              alt={t("post")}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
      <Commitment
        event={event}
        profile={profile}
        interactionDisabled={interactionDisabled}
      />
      <ReactionAndComments
        event={event}
        profile={profile}
        setIsCommentSectionOpened={setIsCommentSectionOpened}
        interactionDisabled={interactionDisabled}
      />
      {isCommentSectionOpened && (
        <CommentSection
          event={event}
          profile={profile}
          interactionDisabled={interactionDisabled}
        />
      )}
      {isReportEventModal && (
        <ReportEvent
          profile={profile}
          event={event}
          isReportEventModal={isReportEventModal}
          setIsReportEventModal={setIsReportEventModal}
        />
      )}
    </Paper>
  );
}
