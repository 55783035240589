import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Paper } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AccountDeleteModal from "../components/AccountDeleteModal";

function Privacy() {
  const { t } = useTranslation(["help", "auth", "common"]);
  const { profile } = useSelector((state) => state.auth);
  const [showModal, setShowModal] = useState(false);
  return (
    <Box className="w-full" alignSelf="center">
      <Paper
        className="max-w-[600px] w-full mx-auto"
        elevation={3}
        sx={{
          padding: "2rem 1rem",
          my: 2,
          borderRadius: 3,
        }}
      >
        {profile?._id && (
          <div className="text-center">
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => setShowModal(true)}
            >
              {t("auth:deleteAccount")}
            </Button>
          </div>
        )}
      </Paper>
      <AccountDeleteModal
        showModal={showModal}
        handleCloseModal={() => setShowModal(false)}
        profile={profile}
        t={t}
      />
    </Box>
  );
}

export default Privacy;
