import {createSlice } from "@reduxjs/toolkit";

const initialState = {
    isSend: true,
};


export const messageBarSlice = createSlice({
  name: "status_bar",
  initialState,
  reducers: {
    setBarStatus: (state, action) => {
        state.isSend = action.payload.isSend;
    },
  },
});

export default messageBarSlice.reducer;
export const { setBarStatus } = messageBarSlice.actions;
