import { Avatar, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { setUserId, toggleModal } from "../redux/userImagesSlice";

export default function UserAvatar({ userId, user, username = "ykop" }) {
  const dispatch = useDispatch();
  const handleClick = async () => {
    await dispatch(setUserId(userId));
    dispatch(toggleModal());
  };
  return (
    <Button
      onClick={(e) => {
        if (user?.username && !user?.deletedAccount) handleClick();
      }}
      sx={{ p: 0, m: 0, mb: 1, minWidth: "unset" }}
    >
      <Avatar src={user?.profilePic?.url || null} alt={username} />
    </Button>
  );
}
