import React, {useState } from "react";
import {Box,Grid,Container, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import SideBar from './SideBar'
import Chat from './Chat';
import { io } from 'socket.io-client';
import { useDispatch,useSelector } from 'react-redux';
import {setliveUsers} from '../../redux/liveUserSlice'
import {conversation,setConversations} from  '../../redux/conversationSlice'
import { useSearchParams } from 'react-router-dom';
import Utils from "../../utils/Utils";
import { useSocket } from '../../provider/SocketContext';
import CuModel from '../../components/CuModel'
import ReactPlayer from 'react-player'
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import {showSideBar,showThread} from '../../redux/mobileChatSlice'

const { socketURL } = Utils;

function Messages() {
  const data = useSelector(state=>state.conversation.conversations)
  const mobileChatData    = useSelector(state=>state.mobileChatSlice)
  const { connectSocket, socket } = useSocket();
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams();
  const thread_id = searchParams.get('thread_id')
  const [isScrollDown,setScrollDown] = useState(false)
  const [openVideoPreviewModel,setPreviewModel] = useState(false)
  const userProfileData = useSelector((state) => state.auth);
  const [fileUrl,setUrl] = useState('')
  const [fileType,setFileType] = useState('') 
  const [fileName,setFileName] = useState('') 
  const [downloadLoader,setDownloadLoader] = useState(true) 
  const [showDownloadBtn,setShowDownloadBtn] = useState(true)
  const matches           = useMediaQuery('(max-width:899px)');

  React.useEffect(()=>{
    if (userProfileData.isLoggedIn){
      let userId_ = userProfileData.profile._id
      connectSocket(socketURL, userId_);
    }
  },[userProfileData])

  React.useEffect(()=>{
    let userDetails = localStorage.getItem('user_details') || "{}"
    let userDetails_ = JSON.parse(userDetails)
    if (Object.keys(userDetails_).length > 0){
      dispatch(conversation(userDetails_._id))
    }
  },[])

  React.useEffect(()=>{
    socket?.on('getLiveUser',connectedUser=>{
      dispatch(setliveUsers({liveUsers:connectedUser}))
    })
  },[socket])

  const scrollDownHandler_ = () =>{
    setScrollDown(true)
  }

  const closeVideoModelHandler = () =>{
    setPreviewModel(false)
    setUrl('')
    setDownloadLoader(false)
    setShowDownloadBtn(false)
  } 

  const openViewModelHandler = (url,type,fileName) =>{  
    setPreviewModel(true)
    setShowDownloadBtn(true)
    setFileType(type)
    setFileName(fileName)
    setUrl(url)
    setDownloadLoader(false)
  } 

  const downloadFile = async () => {
    try {
      setDownloadLoader(true)
      const response = await fetch(fileUrl);
      if (!response.ok) throw new Error("Network response was not ok.");
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownloadLoader(false)
    } catch (error) {
      console.error("Failed to download the file:", error);
      setDownloadLoader(false)
    }
  };

  React.useEffect(()=>{
    if (thread_id){
        if (matches){
          dispatch(showSideBar({show_sidebar:false}))
          dispatch(showThread({show_thread:true}))
        } else {
          dispatch(showSideBar({show_sidebar:true}))
          dispatch(showThread({show_thread:true}))
        }
    } else {
      dispatch(showSideBar({show_sidebar:true}))
      dispatch(showThread({show_thread:false}))
    }    
  },[thread_id,matches])

  return (
    <>
      { data && data.length  > 0 ? 
        <Grid className='chat_container' container mt={3} height={"100%"} border={"1px solid #f0f2f5"}>
          {
            mobileChatData.show_sidebar &&
            <Grid item xs={12} md={3.5} sx={{height:'635px',overflowY:'auto'}} borderRight={"1px solid #f0f2f5"}>
              <Box>
                <SideBar socket={socket} scrollDownHandler={()=>scrollDownHandler_()}/>
              </Box>
            </Grid>
          }
          
          {
            mobileChatData.show_thread &&
            <Grid item xs={12} md={8.5} >
              <Box>
                {thread_id &&
                  <Chat openViewModel={(url,type,name)=>{openViewModelHandler(url,type,name)}} scrollDown={isScrollDown} socket={socket}/>
                }
              </Box>
            </Grid>
          }

        </Grid>
        :
        <Grid container mt={3} alignItems={"center"} textAlign={"center"}>
          <Grid item xs={12} >
            <Box>Data not found</Box>
          </Grid>
        </Grid>
      }

      <CuModel class_="videoModel fileView" open={openVideoPreviewModel} onClose={()=>{closeVideoModelHandler()}}>
        <Box p={4}>
          <Grid container>
            <Grid item xs={12} textAlign={"right"} mb={"10px"}>
              <Box>
                <CloseIcon sx={{cursor:"pointer"}} onClick={()=>closeVideoModelHandler()}/>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {
                fileType === 'video'? 
                  <Box>
                    <ReactPlayer width={"100%"} url={fileUrl}  playing controls/>
                  </Box>
                : fileType === 'image'?
                  <Box>
                    <img 
                      src={fileUrl}
                      onError={(e) => {
                        setShowDownloadBtn(false)
                      }} 
                      style={{margin:'0 auto'}}
                      alt={fileName}
                    />
                  </Box>
                :
                <Box>
                  <Typography>
                    {fileName}
                  </Typography>
                </Box>
              }
            </Grid>
            {
              showDownloadBtn && 
              <Grid item xs={12} textAlign={"right"} mt={"10px"}>
                <Box>
                  <Button 
                    variant="outlined"
                    onClick={downloadLoader ? null : downloadFile} 
                    className={downloadLoader ? "active_loader" : ""}
                  >
                    {
                      downloadLoader ? 
                      <CircularProgress style={{height:"20px",width:"20px"}} />
                      :
                      "Download"
                    }
                  </Button>
                </Box>
              </Grid>
            }
            
          </Grid>
        </Box>
      </CuModel>
    </>
  )
}

export default Messages;
