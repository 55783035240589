import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import Logout from "@mui/icons-material/Logout";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import {
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { clearAlertMessage, logout } from "../redux/authSlice";
import Alert from "./Alert";
import Subscriptions from "./Subscriptions";
import ActiveDirectoryIcon from "./icons/ActiveDirectoryIcon";
import ActiveEventsIcon from "./icons/ActiveEventsIcon";
import ActiveFeedsIcon from "./icons/ActiveFeedsIcon";
import ActiveMessagesIcon from "./icons/ActiveMessagesIcon";
import DirectoryIcon from "./icons/DirectoryIcon";
import EventsIcon from "./icons/EventsIcon";
import FeedsIcon from "./icons/FeedsIcon";
import MessagesIcon from "./icons/MessagesIcon";

export default function AccountMenu({ mode, setMode }) {
  const { t, i18n } = useTranslation(["navbar"]);
  const { allLanguages: languages } = i18n;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    isLoggedIn,
    profile,
    alertMessage,
    isAlertMessage,
    alertMessageType,
  } = useSelector((state) => state.auth);
  const { counter } = useSelector((state) => state.notification);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const [isSub, setIsSub] = useState(false);
  const [language, setLanguage] = useState(i18n.language);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang, () => setLanguage(lang));
  };

  const handleClickNavChange = () =>{
    localStorage.removeItem('thread_id_')
  }

  return (
    <>
      <Box
        sx={{
          display: isLoggedIn ? "flex" : "grid",
          gridTemplateColumns: "1fr auto 1fr",
          alignItems: "center",
          px: 2,
          bgcolor: theme.palette.background.paper,
          position: "sticky",
          top: 0,
          zIndex: 10,
          height: "4rem",
        }}
      >
        <Box sx={{ textAlign: "center", marginRight: "auto" }}>
          {isLoggedIn && (
            <NavLink to="/feeds" onClick={()=>{(handleClickNavChange())}}>
              {({ isActive }) => (
                <Tooltip title={t("feeds")}>
                  <IconButton>
                    {isActive ? (
                      <ActiveFeedsIcon
                        sx={{
                          width: "36px",
                          height: "36px",
                          p: 0.4,
                        }}
                        theme={theme}
                      />
                    ) : (
                      <FeedsIcon
                        sx={{
                          width: "36px",
                          height: "36px",
                          p: 0.4,
                        }}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              )}
            </NavLink>
          )}
          {isLoggedIn && (
            <NavLink to="/events" onClick={()=>{(handleClickNavChange())}}>
              {({ isActive }) => (
                <Tooltip title={t("events")}>
                  <IconButton>
                    {isActive ? (
                      <ActiveEventsIcon
                        sx={{
                          width: "36px",
                          height: "36px",
                          p: 0.4,
                        }}
                        theme={theme}
                      />
                    ) : (
                      <EventsIcon
                        sx={{
                          width: "36px",
                          height: "36px",
                          p: 0.4,
                        }}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              )}
            </NavLink>
          )}
          {isLoggedIn && (
            <NavLink to="/messages" onClick={()=>{(handleClickNavChange())}}>
              {({ isActive }) => (
                <Tooltip title={t("messages")}>
                  <IconButton>
                    {isActive ? (
                      <Badge badgeContent={counter} color="primary">
                        <ActiveMessagesIcon
                          sx={{
                            width: "36px",
                            height: "36px",
                            p: 0.4,
                          }}
                          theme={theme}
                        />
                      </Badge>
                    ) : (
                      <Badge badgeContent={counter} color="primary">
                        <MessagesIcon
                          sx={{
                            width: "36px",
                            height: "36px",
                            p: 0.4,
                          }}
                        />
                      </Badge>
                    )}
                  </IconButton>
                </Tooltip>
              )}
            </NavLink>
          )}
          <NavLink to="/" onClick={()=>{(handleClickNavChange())}}>
            {({ isActive }) => (
              <Tooltip title={t("directory")}>
                <IconButton>
                  {isActive ? (
                    <ActiveDirectoryIcon
                      sx={{
                        maxWidth: "36px",
                        maxHeight: "36px",
                        transform: "rotateZ(90deg)",
                        p: 0.4,
                      }}
                      theme={theme}
                    />
                  ) : (
                    <DirectoryIcon
                      sx={{
                        maxWidth: "36px",
                        maxHeight: "36px",
                        transform: "rotateZ(90deg)",
                        p: 0.4,
                      }}
                    />
                  )}
                </IconButton>
              </Tooltip>
            )}
          </NavLink>
        </Box>
        {!isLoggedIn && (
          <Box
            sx={{
              maxWidth: "5rem",
              overflow: "hidden",
              "& img": { width: "100%" },
              py: 1,
            }}
          >
            <NavLink to="">
              <img className="max-w-[2.5rem]" src="/logo.png" alt="YKOP" />
            </NavLink>
          </Box>
        )}
        <Box
          sx={{ marginLeft: "auto" }}
          className="flex flex-1 justify-end items-center"
        >
          {!isLoggedIn ? (
            <>
              <Box sx={{ display: "flex", flexFlow: "row nowrap" }}>
                <NavLink
                  to="/register"
                  className="text-blue-600 px-2 py-3 hover:underline"
                >
                  <Typography
                    sx={{ fontSize: "1.05rem", whiteSpace: "nowrap" }}
                  >
                    {t("signup")}
                  </Typography>
                </NavLink>
                <NavLink
                  to="/login"
                  className="text-blue-600 px-2 py-3 hover:underline"
                >
                  <Typography
                    sx={{ fontSize: "1.05rem", whiteSpace: "nowrap" }}
                  >
                    {t("login")}
                  </Typography>
                </NavLink>
              </Box>
              <Box>
                <FormControl sx={{ m: 0.5 }}>
                  <Select
                    labelId="visibility"
                    id="visibility"
                    value={language.split("-")[0]}
                    onChange={(e) => handleLanguageChange(e.target.value)}
                    sx={{
                      "& > div": {
                        display: "flex",
                        alignItems: "center",
                        p: 0.25,
                        "& > div": {
                          minWidth: "unset",
                          ml: 1,
                        },
                      },
                    }}
                  >
                    {languages.map((lan) => (
                      <MenuItem key={lan.code} value={lan.code}>
                        <ListItemText>{lan.nativeName}</ListItemText>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </>
          ) : (
            <Tooltip title={t("account")}>
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar
                  src={profile.profilePic?.url || ""}
                  sx={{
                    width: 32,
                    height: 32,
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            setIsSub(true);
            handleClose();
          }}
          color="inherit"
        >
          <ListItemIcon>
            <SubscriptionsIcon fontSize="small" />
          </ListItemIcon>
          {t("subscriptions")}
        </MenuItem>
        <MenuItem onClick={handleClose} component={NavLink} to="/profile">
          <ListItemIcon>
            <ManageAccountsRoundedIcon fontSize="small" />
          </ListItemIcon>
          {t("profile")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setMode((prev) => (prev === "light" ? "dark" : "light"));
            handleClose();
          }}
          color="inherit"
        >
          <ListItemIcon>
            {mode === "dark" ? (
              <Brightness7Icon fontSize="small" />
            ) : (
              <Brightness4Icon fontSize="small" />
            )}
          </ListItemIcon>
          {t("toggleTheme")}
        </MenuItem>
        <MenuItem onClick={handleClose} component={NavLink} to="/help">
          <ListItemIcon>
            <HelpCenterIcon fontSize="small" />
          </ListItemIcon>
          {t("help")}
        </MenuItem>
        <MenuItem onClick={handleClose} component={NavLink} to="/terms">
          <ListItemIcon>
            <LibraryBooksIcon fontSize="small" />
          </ListItemIcon>
          {t("terms")}
        </MenuItem>
        <MenuItem onClick={handleClose} component={NavLink} to="/privacy">
          <ListItemIcon>
            <PrivacyTipIcon fontSize="small" />
          </ListItemIcon>
          {t("privacy")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            dispatch(logout()).then(() => {
              navigate(`/login`);
            });
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t("logout")}
        </MenuItem>
      </Menu>
      <Alert
        isAlertMessage={isAlertMessage}
        alertMessage={alertMessage}
        alertMessageType={alertMessageType}
        onClose={() => dispatch(clearAlertMessage())}
      />
      <Subscriptions isSub={isSub} setIsSub={setIsSub} />
    </>
  );
}
