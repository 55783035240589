import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Alert from "../components/Alert";
import InterestSubscription from "../components/InterestSubscription";
import Loader from "../components/Loader";
import SearchBox from "../components/SearchBox";
import SuggestionModal from "../components/SuggestionModal";
import { removeSubscription } from "../redux/authSlice";
import {clearAlertMessage,fetchDeepParent,fetchNodes,newSuggestion,} from "../redux/nodesSlice";
import "../style/Selector.css";
import Utils from "../utils/Utils";
import {getNotificationCounter} from '../redux/notificationCounter'

const { getObjectByPath, pathSeparator } = Utils;
const fetchedParentIDs = [];

function Directory() {
  const { t } = useTranslation(["directory", "common"]);
  const { status, nodes, alertMessage, isAlertMessage, alertMessageType } = useSelector((state) => state.nodes);
  const { isLoggedIn, profile } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const theme = useTheme();
  const [current, setCurrent] = useState({});
  const pathToFetch = useRef("");
  const [showModal, setShowModal] = useState(false);
  const [isInterestSub, setIsInterestSub] = useState(false);
  const [textInput, setTextInput] = useState("");
  const [result, setResult] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [paths, setPaths] = useState([]);
  const [viewLink, setViewLink] = useState("/");

  // fetch nodes when first mounts
  useEffect(() => {
    if (!nodes.name) {
      dispatch(fetchNodes({ path: pathSeparator }));
      return;
    }
    if (!current.name)
      setCurrent((prev) => {
        if (!prev.path && nodes.path) return nodes;
        if (prev.path && nodes.path && prev.path !== nodes.path) return nodes;
        return prev;
      });
  }, [nodes, dispatch, current]);

  // fetch more nodes when current has no children
  useEffect(() => {
    if (!current.name || !current.parent || current.children.length > 0) return;
    if (fetchedParentIDs.findIndex((item) => item === current._id) < 0) {
      dispatch(fetchNodes({ path: current.path }));
      pathToFetch.current = current.path;
      fetchedParentIDs.push(current._id);
    } else {
      const newParent = getObjectByPath(nodes, pathToFetch.current);
      if (newParent?.children?.length > 0) setCurrent(newParent);
    }
  }, [current, dispatch, nodes]);

  // update the parent when clicked on children
  const updateParent = useCallback((id) => {
    setCurrent((prev) => prev.children.find((item) => item._id === id));
  }, []);
  const deepPathToFetch = useRef("");

  // set parent by fetching if not available locally
  const setDeepParent = useCallback(
    async ({ pathToParent, isLocal, newParentID }) => {
      if (isLocal) updateParent(newParentID);
      else {
        dispatch(fetchDeepParent({ path: pathToParent }));
        deepPathToFetch.current = pathToParent;
      }
    },
    [updateParent, dispatch]
  );

  // update current when deep parent fetched
  useEffect(() => {
    if (deepPathToFetch.current) {
      const newParent = getObjectByPath(nodes, deepPathToFetch.current);
      if (newParent) {
        setCurrent(newParent);
        deepPathToFetch.current = "";
      }
    }
  }, [nodes]);


  useEffect(()=>{
    let intervalId;
    if (profile) {
      intervalId = setInterval(() => {
        if (profile) {
          dispatch(getNotificationCounter(profile._id));
        }
      }, 5000);
    } else {
      return () => {
        clearInterval(intervalId);
      };
    };
  },[])

  useEffect(()=>{
    if (profile) {
      dispatch(getNotificationCounter(profile._id));
    }
  },[])


  // breadcrumbs click
  const removePathAndAdd = useCallback(
    (level) => {
      const splitted = current.path.split(pathSeparator).filter(Boolean);
      const levelIndex = splitted.indexOf(level);
      if (levelIndex !== -1) {
        const newPath = `${pathSeparator}${splitted
          .slice(0, levelIndex + 1)
          .join(pathSeparator)}`;
        setCurrent(getObjectByPath(nodes, newPath));
      }
    },
    [current, nodes]
  );

  const pathRef = useRef(null);
  useEffect(() => {
    if (!pathRef.current) return;
    const container = pathRef.current;
    container.scrollLeft = container.scrollWidth - container.clientWidth;
  }, [current]);

  useEffect(() => {
    if (current?.path) {
      const p = current.path.split(pathSeparator).filter(Boolean);
      setPaths(p);
      setIsExpanded(false);      
      if (p.length === 1) {
        setViewLink(`/${encodeURIComponent(p[0])}`);
      } else if (p.length >= 2) {
        setViewLink(
          `/${encodeURIComponent(p.at(-2))}/${encodeURIComponent(p.at(-1))}`
        );
      }
    }
  }, [current]);


  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setTextInput("");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setResult("");
    const suggestion = {
      name: textInput,
      path: `${current.path}${pathSeparator}${textInput}`,
      parent: current._id,
      suggested: true,
      searchable: true,
    };
    await dispatch(newSuggestion(suggestion));
    handleCloseModal();
  };

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box sx={{ width: "100%", alignSelf: "center" }}>
      <Paper
        className="max-w-[600px] w-full mx-auto"
        elevation={3}
        sx={{
          mx: "auto",
          padding: "2rem 1rem",
          my: 2,
          borderRadius: 3,
        }}
      >
        <Typography variant="h4" component="h1" textAlign="center" my={2}>
          {t("selectYourInterest")}
        </Typography>
        {status === "success" ? (
          <>
            <SearchBox
              parent={current}
              updateParent={updateParent}
              setDeepParent={setDeepParent}
              t={t}
            />
            <Box>
              <Breadcrumbs
                className="path"
                ref={pathRef}
                maxItems={100}
                sx={{
                  flexWrap: "nowrap",
                  "& ol": {
                    flexWrap: "nowrap",
                    "& li": {
                      display: "flex",
                      flexWrap: "nowrap",
                      alignItems: "center",
                    },
                  },
                  mt: 1,
                }}
                aria-label="breadcrumb"
              >
                {paths && paths.length > 3 && !isExpanded ? (
                  <>
                    <Button
                      variant="text"
                      color="inherit"
                      sx={{ textTransform: "none", minWidth: "unset" }}
                      onClick={() => removePathAndAdd(paths[0])}
                    >
                      <Typography variant="span" sx={{ whiteSpace: "nowrap" }}>
                        {paths[0]}
                      </Typography>
                    </Button>
                    {paths.length !== 4 && (
                      <>
                        <Typography variant="span" sx={{ mx: 1 }}>
                          /
                        </Typography>
                        <Button
                          variant="contained"
                          color="inherit"
                          size="small"
                          sx={{
                            minWidth: "unset",
                            py: 0.05,
                            px: 0.05,
                            mx: 0.5,
                          }}
                          onClick={handleClick}
                        >
                          <Typography variant="span" sx={{ px: 0.75 }}>
                            &bull;&bull;&bull;
                          </Typography>
                        </Button>
                      </>
                    )}
                    {paths
                      .slice(-3)
                      .slice(0, -1)
                      .map((level) => (
                        <>
                          <Typography variant="span" sx={{ mx: 0.75 }}>
                            /
                          </Typography>
                          <Button
                            variant="text"
                            color="inherit"
                            key={level}
                            sx={{
                              textTransform: "none",
                              minWidth: "unset",
                            }}
                            onClick={() => removePathAndAdd(level)}
                          >
                            <Typography
                              variant="span"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {level}
                            </Typography>
                          </Button>
                        </>
                      ))}
                  </>
                ) : (
                  paths.slice(0, -1).map((level) => (
                    <Button
                      variant="text"
                      color="inherit"
                      key={level}
                      sx={{ textTransform: "none", minWidth: "unset" }}
                      onClick={() => removePathAndAdd(level)}
                    >
                      <Typography variant="span" sx={{ whiteSpace: "nowrap" }}>
                        {level}
                      </Typography>
                    </Button>
                  ))
                )}
              </Breadcrumbs>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  mx: 1,
                  my: 1,
                }}
              >
                <Tooltip title={t("viewFeed")} placement="top">
                  <NavLink to={viewLink}>
                    <IconButton color="info">
                      <VisibilityIcon />
                    </IconButton>
                  </NavLink>
                </Tooltip>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  sx={{ color: "#4caf50", mx: 1 }}
                >
                  {current.name}
                </Typography>
                {isLoggedIn && (
                  <Box component="span">
                    {profile?.subscribedInterests?.findIndex(
                      (i) => i.id === current._id
                    ) > -1 ? (
                      <Tooltip title={t("common:unsubscribe")} placement="top">
                        <IconButton
                          color="error"
                          onClick={() => {
                            dispatch(
                              // eslint-disable-next-line no-underscore-dangle
                              removeSubscription({ id: current._id })
                            );
                          }}
                          aria-label="unsubscribe"
                        >
                          <RemoveCircleIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title={t("common:subscribe")} placement="top">
                        <IconButton
                          color="success"
                          onClick={() => setIsInterestSub(true)}
                          aria-label="subscribe"
                        >
                          <AddCircleIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                )}
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", m: 1 }}>
                <Button
                  variant="outlined"
                  sx={{ borderRadius: 10 }}
                  onClick={handleShowModal}
                  startIcon={<AddIcon />}
                >
                  {t("suggest")}
                  {current.name}
                </Button>
              </Box>
              <Box className="children">
                {/* eslint-disable-next-line no-nested-ternary */}
                {current.children?.length === 0 ? (
                  status === "loading" ? (
                    <Loader />
                  ) : (
                    <Box textAlign="center" my={2}>
                      <span className="dash-bordered">No more node</span>
                    </Box>
                  )
                ) : (
                  current.children?.map((child) => (
                    <Button
                      color="inherit"
                      key={child.path}
                      variant="text"
                      sx={{
                        justifyContent: "flex-start",
                        color: theme.palette.text.primary,
                      }}
                      // eslint-disable-next-line no-underscore-dangle
                      onClick={() => updateParent(child._id)}
                    >
                      <Typography>{child.name}</Typography>
                    </Button>
                  ))
                )}
              </Box>
            </Box>
          </>
        ) : (
          <Loader />
        )}
        {showModal && (
          <SuggestionModal
            showModal={showModal}
            handleCloseModal={handleCloseModal}
            handleShowModal={handleShowModal}
            handleSubmit={handleSubmit}
            result={result}
            parent={current}
            setTextInput={setTextInput}
            t={t}
          />
        )}
        {isInterestSub && (
          <InterestSubscription
            profile={profile}
            interest={current}
            isInterestSub={isInterestSub}
            setIsInterestSub={setIsInterestSub}
            t={t}
          />
        )}
        <Alert
          isAlertMessage={isAlertMessage}
          alertMessage={alertMessage}
          alertMessageType={alertMessageType}
          onClose={() => dispatch(clearAlertMessage())}
        />
      </Paper>
    </Box>
  );
}

export default Directory;
