import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    show_thread: false,
    show_sidebar: false,
};

export const mobileChatSlice = createSlice({
  name: "mobileChatSlice",
  initialState,
  reducers: {
    showThread: (state, action) => {
      state.show_thread = action.payload.show_thread;
    },
    showSideBar: (state, action) => {
        state.show_sidebar = action.payload.show_sidebar;
    },
  },
});

export default mobileChatSlice.reducer;
export const { showThread,showSideBar} = mobileChatSlice.actions;
