import Utils from "./Utils";

const { getObjectByPath, pathSeparator, serverURL } = Utils;
let nodes = {};

async function fetchNodes(path, isFull) {
  let result;
  try {
    result = await fetch(`${serverURL}/nodes`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ path, isFull }),
    });
    if (result.status === 200) {
      const data = await result.json();
      if (!data.name) return nodes;
      if (data.parent === null) {
        nodes = { ...data };
      } else {
        getObjectByPath(nodes, data.path).children = data.children;
      }
      return nodes;
    }
    return {
      isError: true,
      error: await result.text(),
    };
  } catch (error) {
    return {
      isError: true,
      error: error.message,
    };
  }
}
async function search(key) {
  let result;
  try {
    result = await fetch(`${serverURL}/search`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ key }),
    });
    if (result.status === 200) {
      return await result.json();
    }
    return {
      isError: true,
      error: await result.text(),
    };
  } catch (error) {
    return {
      isError: true,
      error: error.message,
    };
  }
}
async function newSuggestion(suggestion) {
  let result;
  try {
    result = await fetch(`${serverURL}/suggestions/new`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify(suggestion),
    });
    if (result.status === 200) {
      return await result.text();
    }
    return {
      isError: true,
      error: await result.text(),
    };
  } catch (error) {
    return {
      isError: true,
      error: error.message,
    };
  }
}
async function getSuggestions() {
  let result;
  try {
    result = await fetch(`${serverURL}/suggestions`, {
      method: "GET",
      credentials: "include",
    });
    if (result.status === 200) {
      return await result.json();
    }
    return {
      isError: true,
      error: await result.text(),
    };
  } catch (error) {
    return {
      isError: true,
      error: error.message,
    };
  }
}
async function updateSuggestion(id, action) {
  let result;
  try {
    result = await fetch(`${serverURL}/suggestions/${action}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ id, action }),
    });
    if (result.status === 200) {
      return await result.json();
    }
    return {
      isError: true,
      error: await result.text(),
    };
  } catch (error) {
    return {
      isError: true,
      error: error.message,
    };
  }
}
async function fetchDeepParent(path) {
  try {
    const properties = path.split(pathSeparator).filter(Boolean).slice(1);
    let parent = nodes;
    let failedLevel;
    for (const property of properties) {
      const match = parent.children.find((child) => child.name === property);
      if (!match) {
        failedLevel = property;
        break;
      }
      parent = match;
    }
    const result = await fetch(`${serverURL}/nodes`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        path: path.split(`${pathSeparator}${failedLevel}`)[0],
        isFull: true,
        endingPath: path,
      }),
    });
    if (result.status === 200) {
      const data = await result.json();
      if (!data.name) return nodes;
      if (data.parent === null) {
        nodes = { ...data };
      } else {
        getObjectByPath(nodes, data.path).children = data.children;
      }
      return nodes;
    }
    return {
      isError: true,
      error: await result.text(),
    };
  } catch (error) {
    return {
      isError: true,
      error: error.message,
    };
  }
}


async function getConversations() {
  let result;
  try {
    result = await fetch(`${serverURL}/chat/conversations/`, {
      method: "GET",
      credentials: "include",
    });
    if (result.status === 200) {
      return await result.json();
    }
    return {
      isError: true,
      error: await result.text(),
    };
  } catch (error) {
    return {
      isError: true,
      error: error.message,
    };
  }
}
function getNodes() {
  return nodes;
}

async function updateIsBlockStatus(thread_id,blocked_id) {
  try {
    const response = await fetch(`${serverURL}/chat/conversations_block/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ conversation_id:thread_id,blocked_id,is_block:true }),
    });
  
    if (response.status === 200) {
      return true
    } else {
      return false
    }
    
  } catch (error) {
    return false
  }
}

export default {
  getNodes,
  fetchNodes,
  search,
  newSuggestion,
  fetchDeepParent,
  getSuggestions,
  updateSuggestion,
  getConversations,
  updateIsBlockStatus
};
