import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box, Button, Modal, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import Zoom from "@mui/material/Zoom";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteAccount } from "../redux/authSlice";

function AccountDeleteModal({ showModal, handleCloseModal, t, profile }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      deleteAccount({
        id: profile._id,
        password,
      })
    ).then((result) => {
      if (result.error) toast.error(result.error.message);
      else {
        navigate("/");
      }
    });
  };
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Modal
      open={showModal}
      onClose={handleCloseModal}
      aria-labelledby="node-suggestion-modal"
      aria-describedby="Modal to suggest new node"
      sx={{
        overflowY: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiPaper-root": {
          position: "unset",
          zIndex: 20000,
          margin: "auto",
        },
      }}
    >
      <Zoom in={showModal}>
        <Paper
          elevation={2}
          sx={{
            display: "block",
            position: "absolute",
            top: 0,
            maxWidth: "600px",
            width: "100%",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 4,
            p: 3,
            minWidth: "280px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Typography variant="h5" component="h2">
              {t("auth:confirmAccountDeletion")}
            </Typography>
            <IconButton onClick={() => handleCloseModal()} sx={{ ml: "auto" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box textAlign="center" mb={2}>
            <DeleteForeverIcon
              color="error"
              sx={{
                fontSize: "3rem",
              }}
            />
          </Box>
          <form onSubmit={handleSubmit}>
            <FormControl
              sx={{
                width: "100%",
                my: 1,
                bgcolor: "transparent",
              }}
              variant="outlined"
              required
            >
              <InputLabel htmlFor="outlined-adornment-password">
                {t("auth:enterPassword")}
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                label={t("enterPassword")}
              />
            </FormControl>
            <Box sx={{ display: "flex", mt: 3 }}>
              <Button
                sx={{ m: 0.5, ml: "auto", borderRadius: 10 }}
                variant="outlined"
                color="success"
                onClick={handleCloseModal}
                type="reset"
              >
                {t("common:cancel")}
              </Button>
              <Button
                sx={{ m: 0.5, borderRadius: 10 }}
                color="error"
                variant="outlined"
                type="submit"
              >
                {t("common:delete")}
              </Button>
            </Box>
          </form>
        </Paper>
      </Zoom>
    </Modal>
  );
}

export default AccountDeleteModal;
