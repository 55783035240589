import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    Button,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    deleteImage,
    setProfilePic,
    uploadImages,
} from '../../redux/authSlice';
import Utils from '../../utils/Utils';
import FileUploader from '../commons/FileUploader';
import LabelSelection from './LabelSelection';
import ProfileImage from './ProfileImage';
const { serverURL } = Utils;

export default function ProfileImages({
    profile,
    t,
    selectedLabels,
    setSelectedLabels,
    updateLabels,
}) {
    const dispatch = useDispatch();
    const [imageUploaderOpen, setImageUploaderOpen] = useState(false);

    const { images, profilePic } = profile;

    const onFileSelect = (files) => {
        const formData = new FormData();
        if (files.length > 0) {
            files.forEach((file) => {
                formData.append('images', file, file.name);
            });
            dispatch(uploadImages(formData));
        }
    };

    const handleDeleteImage = (image) => {
        dispatch(deleteImage(image));
    };

    const handleProfilePicChange = (image) => {
        dispatch(setProfilePic(image));
    };
    return (
        <Box>
            <Avatar
                src={profilePic?.url || null}
                alt={profile?.username || t('defaultProfile')}
                sx={{ width: '150px', height: '150px', m: 'auto' }}
            />
            <LabelSelection
                profile={profile}
                t={t}
                selectedLabels={selectedLabels}
                setSelectedLabels={setSelectedLabels}
                updateLabels={updateLabels}
            />
            {imageUploaderOpen && (
                <FileUploader
                    onFileSelect={onFileSelect}
                    acceptedFileTypes={{ 'image/*': [] }}
                    instructionText={t('common:dragAndDropImage')}
                />
            )}
            <Accordion
                className="my-1"
                sx={{
                    backgroundColor: 'transparent',
                    backgroundImage: 'unset',
                    boxShadow: 'unset',
                    '&:before': {
                        backgroundColor: 'transparent',
                    },
                }}
            >
                <AccordionSummary
                    sx={{ padding: 0 }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="flex-row-reverse"
                >
                    <Box className="flex w-full justify-between">
                        <Typography variant="h5">
                            {t('yourPictures')}
                        </Typography>
                        <Button
                            variant="outlined"
                            color="inherit"
                            onClick={(e) => {
                                e.stopPropagation();
                                setImageUploaderOpen((prev) => !prev);
                            }}
                        >
                            {t('common:upload')}
                        </Button>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    {images?.length > 0 && (
                        <Box className="my-2 columns-2 gap-2">
                            {images.map((image) => (
                                <ProfileImage
                                    key={image.key}
                                    image={image}
                                    handleDeleteImage={handleDeleteImage}
                                    handleProfilePicChange={
                                        handleProfilePicChange
                                    }
                                    t={t}
                                />
                            ))}
                        </Box>
                    )}
                    {!images?.length && (
                        <Typography className="text-center pb-2">
                            {t('youHaveNoUploads')}
                        </Typography>
                    )}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}
